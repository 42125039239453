.sidebar-container {
    width: 28%;
    margin: 2em 0 0 0; 
    h2 {
        color: $black;
        font-family: $accentfont;
        font-size: 42px;
        font-weight: 600;
    }
    .sidebar {
        background: $grey;
        padding: 2em;
        margin: 30px 0 0 0;
        .search {
            position: relative;
            .search-field {
                width: 100%;
            }
            .search-button {
                position: absolute;
                background: none;
                color: $purple;
                border: unset;
                top: 0;
                right: 0;
                font-size: 15px;
            }
        }
        h3 {
            color: $black;
            font-family: $font;
            font-size: 20px;
            font-weight: bold;
        }
        .filter {
            padding-top: 15px;
            color: $black;
            font-family: $font;
            div {
                margin: 20px 0;
                cursor: pointer;
                &:first-child {
                    margin: 0;
                }
            }
            input[type="radio"] {
                margin-left: 0;
                cursor: pointer;
            }
        }
        .header-button {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 30px;
            button {
                color: #fff;
                font-size: 18px;
                line-height: 40px;
                width: 140px;
                background: #6100ff;
                border: 1px solid #6100ff;
                border-radius: 22px;
                height: 45px;
                font-weight: 700;
                font-family: "Source Sans Pro", sans-serif;
                cursor: pointer;
                transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            }
        }
    }
}