.table {
    width: 70%;
    margin: 2em 0;
    h2 {
        color: $black;
        font-family: $accentfont;
        font-size: 42px;
        font-weight: 600;
    }
    &__container {
        margin: 30px 0 0 0;
    }
    &__row {
        padding: 10px 30px;
        display: flex;
        position: relative;
        .standard {
            display: flex;
        }
        &:nth-child(odd) {
            background: $grey;
        }
        .delete {
            border: none;
            background: transparent;
            cursor: pointer;
            &:after {
                content: "\00d7";
                position: absolute;
                right: 20px;
                top: 5px;
                font-size: 25px;
                color: $black;
            }
            &:hover {
                &:after {
                    color: $red;
                }
            }
        }
        .edit {
            background: none;
            border: unset;
            position: absolute;
            right: 40px;
            top: 12px;
            cursor: pointer;
            &:hover {
                color: $yellow;
            }
        }
        .editing {
            display: flex;
            input[type="text"] {
                height: 45px;
                font-size: 15px;
                margin-bottom: 0;
                margin-top: 0;
                margin-right: 10px;
                padding: 8px;
                border: 1px solid $purple;
                color: $black;
                outline: 0;
                font-family: $font;
                width: 265px;
            }
            select,
            input[type="number"] {
                border: 1px solid $purple;
                width: 75px;
                margin-right: 10px;
                padding: 8px;
            }
        }
    }
    &__row-heading {
        display: flex;
        background: $purple;
        padding: 15px 30px;
        h3 {
            font-family: $font;
            font-size: 18px;
            color: $white;
            font-weight: 600;
        }
    }
    &__item {
        padding-right: 50px;
        font-family: $font;
        font-size: 16px;
        &--project-code {
            width: 150px;
        }
        span {
            display: block;
            font-style: italic;
            font-size: 14px;
            padding-top: 5px;
        }
    }
    &__group {
        display: flex;
    }
}