.wrapper {
    max-width: 1280px;
    width: 90%;
    margin: 0 auto;
}

.content {
    display: flex;
    justify-content: space-between;
}

form {
    input[type="text"],
    input[type="search"] {
        height: 45px;
        font-size: 15px;
        margin-bottom: 0;
        margin-top: 0;
        margin-right: 10px;
        padding: 8px;
        border: 1px solid #bbb;
        color: $black;
        outline: 0;
        font-family: $font;
    }
    button {
        color: $white;
        font-size: 18px;
        line-height: 40px;
        width: 60px;
        background: $purple;
        border: 1px solid $purple;
        border-radius: 22px;
        height: 45px;
        font-weight: 700;
        font-family: $font;
        cursor: pointer;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        &:hover {
            background: $white;
            color: $purple;
            transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        }
    }
    select {
        height: 45px;
        font-size: 15px;
        margin-bottom: 0;
        margin-top: 0;
        margin-right: 10px;
        padding: 8px;
        border: 1px solid #bbb;
        color: $black;
        outline: 0;
        font-family: $font;
        width: 100px;
    }
}