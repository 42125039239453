.form-group {
    background: $grey;
    padding: 2em;
    .form {
        &:first-child {
            padding-bottom: 30px;
            .long-input-name {
                width: 860px;
            }
            .short-input-code {
                max-width: 100px;
                width: 100%;
            }
        }
        &:nth-child(2) {
            .long-input {
                max-width: 996px;
                width: 100%;
            }
            .longest-input {
                max-width: 1106px;
                width: 100%;
                margin-top: 15px;
            }
        }
        &__heading {
            font-weight: bold;
            font-family: $font;
            font-size: 20px;
            margin-bottom: 15px;
        }
        &__container {
            display: flex;
            form {
                display: flex;
            }
            .edit-client-button {
                margin-left: 15px;
                color: $black;
                font-size: 18px;
                line-height: 40px;
                width: 120px;
                background: $teal;
                border: 1px solid $teal;
                border-radius: 22px;
                height: 45px;
                font-weight: 700;
                font-family: $font;
                cursor: pointer;
                transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                &:hover {
                    background: $white;
                    color: $black;
                    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                }
            }
        }
        &__edit-client {
            margin-bottom: 30px;
            .short-input-code {
                width: 100px;
            }
            .long-input-name {
                width: 750px;
            }
            .update-client {
                width: 150px;
            }
        }
        button {
            width: 100px;
        }
    }
    .long-input {
        width: 860px;
    }
    .longest-input {
        width: 970px;
        margin-top: 15px;
    }
}
